// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bits-of-knowledge-attention-js": () => import("./../../../src/pages/bits-of-knowledge/attention.js" /* webpackChunkName: "component---src-pages-bits-of-knowledge-attention-js" */),
  "component---src-pages-bits-of-knowledge-js": () => import("./../../../src/pages/bits-of-knowledge.js" /* webpackChunkName: "component---src-pages-bits-of-knowledge-js" */),
  "component---src-pages-bits-of-knowledge-supporter-js": () => import("./../../../src/pages/bits-of-knowledge/supporter.js" /* webpackChunkName: "component---src-pages-bits-of-knowledge-supporter-js" */),
  "component---src-pages-bits-of-knowledge-tend-to-lack-js": () => import("./../../../src/pages/bits-of-knowledge/tend-to-lack.js" /* webpackChunkName: "component---src-pages-bits-of-knowledge-tend-to-lack-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-labo-bacterial-flora-js": () => import("./../../../src/pages/labo/bacterial-flora.js" /* webpackChunkName: "component---src-pages-labo-bacterial-flora-js" */),
  "component---src-pages-labo-js": () => import("./../../../src/pages/labo.js" /* webpackChunkName: "component---src-pages-labo-js" */),
  "component---src-pages-labo-report-2023-08-js": () => import("./../../../src/pages/labo/report-2023-08.js" /* webpackChunkName: "component---src-pages-labo-report-2023-08-js" */),
  "component---src-pages-labo-variety-of-fiber-js": () => import("./../../../src/pages/labo/variety-of-fiber.js" /* webpackChunkName: "component---src-pages-labo-variety-of-fiber-js" */),
  "component---src-pages-labo-with-teijin-js": () => import("./../../../src/pages/labo/with-teijin.js" /* webpackChunkName: "component---src-pages-labo-with-teijin-js" */),
  "component---src-pages-thirty-years-focus-on-fiber-js": () => import("./../../../src/pages/thirty-years/focus-on-fiber.js" /* webpackChunkName: "component---src-pages-thirty-years-focus-on-fiber-js" */),
  "component---src-pages-thirty-years-js": () => import("./../../../src/pages/thirty-years.js" /* webpackChunkName: "component---src-pages-thirty-years-js" */),
  "component---src-pages-thirty-years-meet-inuria-js": () => import("./../../../src/pages/thirty-years/meet-inuria.js" /* webpackChunkName: "component---src-pages-thirty-years-meet-inuria-js" */),
  "component---src-pages-thirty-years-our-commitment-js": () => import("./../../../src/pages/thirty-years/our-commitment.js" /* webpackChunkName: "component---src-pages-thirty-years-our-commitment-js" */),
  "component---src-pages-thirty-years-tofu-refuse-js": () => import("./../../../src/pages/thirty-years/tofu-refuse.js" /* webpackChunkName: "component---src-pages-thirty-years-tofu-refuse-js" */)
}

